export const environment = {
    production: false,
    language: 'es',
    firebase: {
        apiKey: "AIzaSyCtEDiEuVULUjpFpU1XjqK51l_8Iyimv2w",
        authDomain: "caranty-dev2.firebaseapp.com",
        databaseURL: "https://caranty-dev2-default-rtdb.firebaseio.com",
        projectId: "caranty-dev2",
        storageBucket: "caranty-dev2.appspot.com",
        messagingSenderId: "85730297380",
        appId: "1:85730297380:web:d38b991613c6f4dd86fab1",
        vapidKey: "BGmhsWcJFb83wewlAkZMpIgFWuVWo33b_8W8uFlJf21xyljQTbOIM-LU02Q-V_nrn4ptEaPx43YqMpoTYCQrt3I"
    },
    endPoint: 'https://api-dev.caranty.com/caranty-dev-v2',
    apiHeader: 'api-key',
    apiKey: 'b799ad6c0285497f80f05fdf830c98f4',
    azureApiKey: 'IeITEeTN7UQzHPbjTGoO139sWRnEKZCb5N7F36p4guUiwbvMt0mmaA==',
    authService: {
        authTokenUrl: 'https://us-central1-caranty-dev2.cloudfunctions.net/iCaranty'
    },
    googleMaps: {
        apiKey: 'AIzaSyBT-TmO8OFrzfs-yR91nxIYtP_eaYlkYyw'
    },
    URL_BASE: 'https://dev.caranty.com',
    carantyApi: {
        cloud: 'https://us-central1-caranty-dev2.cloudfunctions.net',
        token: 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword',
        refreshToken: 'https://securetoken.googleapis.com/v1',
        keyToken: 'AIzaSyCtEDiEuVULUjpFpU1XjqK51l_8Iyimv2w'
    },
    whatsapp: {
        link: "https://dev.caranty.com/referrals/reference/"
    },
    messagingSenderId: "85730297380",
    recaptcha: {
        siteKey: '6Lfhg7giAAAAAL4PjyEtyiFTskHggmLFNCuLIlwP'
    },
    domain: 'https://dev.caranty.com'
};
