import { Injectable } from '@angular/core';
import { Database, ref, list, listVal, onValue, getDatabase, orderByChild, equalTo, query, get, set } from '@angular/fire/database';
import { Observable, from, of } from 'rxjs';
import { Firestore, collection, collectionData, doc, orderBy, setDoc, query as queryFirestore, updateDoc } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
// import * as firebase from 'firebase/app';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// import { INotifications } from '../models/messages/messages.model';
// import {} from 'firebase/database'
@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  // public room?: AngularFireList<any>;
  private readonly apiUrl = environment.endPoint;
  private dbRef = getDatabase();

  constructor(
    private db: Database,
    private fb: Firestore,
    private http: HttpClient
  ) { }

  // public getMessages(): Observable<any> {
  //   return this.db.list('rooms').valueChanges();
  // }

  public getMessagesById(id: any, key: string): Observable<any[]> {
    const messagesRef = query(ref(this.db, 'rooms'), orderByChild(key), equalTo(id));

    return new Observable((observer) => {
      const unsubscribe = onValue(messagesRef, (snapshot) => {
        const messages:any = [];
        snapshot.forEach(childSnapshot => {
          messages.push(childSnapshot.val());
        });
        observer.next(messages);
      }, (error) => {
        observer.error(error);
      });

      // Unsubscribe from changes when the Observable is unsubscribed
      return () => unsubscribe();
    });
  }

  /**
   * Método para obtener una conversación
   * @param roomId 
   * GADC 12/01/2022 - Se modifica la salida de la colección de mensajes
   */
  public getCurrentMessage(roomId: string) {
    const roomRef = ref(this.db, `rooms/${roomId}`);
    // return onValue(roomRef, (snapshot) => {
    //   return snapshot.val();
    // });
    
    return list(roomRef).pipe(
      map(changes =>
        changes.map(c => ({
          data: c.snapshot.val(),
          key: c.snapshot.key
        }))
      )
    );
  }

  // public createRoom(info: any, roomId: string): Promise<any> {
  //   return this.db.database.ref('rooms').child(roomId).set(info);
  // }
  public createRoom(info: any, roomId: string) {
    // const roomRef = doc(this.fb, `rooms/${roomId}`);
    // return setDoc(roomRef, info);
    const db = getDatabase();
    return set(ref(db, 'rooms/' + roomId), info);
  }
  

  async sendMessage(roomId: string, messages: any): Promise<void> {
    // const roomRef = doc(this.fb, `rooms/${roomId}`);
    // const messageRef = doc(collection(roomRef, 'messages'));

    // try {
    //   await setDoc(messageRef, messages);
    //   console.log('Message sent successfully');
    // } catch (error) {
    //   console.error('Error sending message: ', error);
    //   throw error;
    // }
    const db = getDatabase();
    const roomMessagesRef = ref(db, `rooms/${roomId}/messages`);
    try {
      await set(roomMessagesRef, messages);
      console.log('Messages updated successfully');
    } catch (error) {
      console.error('Error updating messages:', error);
      throw error;
    }
  }

  async setNewMess(roomId: string, data: any): Promise<any> {
    const db = getDatabase();
    const amountRef = ref(db, `rooms/${roomId}/lastMessage`);
    try {
      await set(amountRef, data);
      console.log('New message updated successfully');
    } catch (error) {
      console.error('Error updating New message:', error);
      throw error;
    }
  }

  public async updateMessStatus(roomId: string): Promise<any> {
    // return this.db.database.ref(`rooms/${roomId}/lastMessage/recieved/value`).set(true);
    const amountRef = ref(this.dbRef, `rooms/${roomId}/lastMessage/recieved/value`);

    try {
      await set(amountRef, true);
      console.log('Message status updated successfully');
    } catch (error) {
      console.error('Error updating message status:', error);
      throw error;
    }
  }

  async updateAmount(roomId: string, amount: number): Promise<any> {
    // return this.db.database.ref(`rooms/${roomId}/amount`).set(amount);
    const db = getDatabase();
    const amountRef = ref(db, `rooms/${roomId}/amount`);

    try {
      await set(amountRef, amount);
      console.log('Amount updated successfully');
    } catch (error) {
      console.error('Error updating amount:', error);
      throw error;
    }
  }

  async updateSellerAmount(roomId: string, amount: number): Promise<any> {
    // return this.db.database.ref(`rooms/${roomId}/sellerAmount`).set(amount);
    const db = getDatabase();
    const amountRef = ref(db, `rooms/${roomId}/sellerAmount`);

    try {
      await set(amountRef, amount);
      console.log('Seller Amount updated successfully');
    } catch (error) {
      console.error('Error updating amount:', error);
      throw error;
    }
  }

  // public addPayAmount(roomId: string, amount: number): Promise<any> {
  //   return this.db.database.ref(`rooms/${roomId}/amount`).set(amount);
  // }

  async updateStatus(roomId: string, status: boolean): Promise<any> {
    const roomRef = doc(this.fb, `rooms/${roomId}`);
    try {
      await updateDoc(roomRef, { active: status });
      console.log('Room status updated successfully');
    } catch (error) {
      console.error('Error updating room status: ', error);
      throw error;
    }
  }

  public sendQuestion(body: any){
    return this.http.post(this.apiUrl + '/notifications/sendQuestion', body);
  }

  // public updateRoomsKeys(data:any, room:string){
  //   return this.db.database.ref(`rooms/${room}`).update(data);
  // }

  // deleteRoom(roomId: string){
  //   return new Promise((resolve, reject) => {
  //     this.db.database.ref(`rooms/${roomId}`).remove().then(res => {
  //       resolve({message: 'success'});
  //     }).catch(error => {
  //       resolve({message: error});
  //     })
  //   })
  // }

  public sendNotification(body: any){
    return this.http.post(this.apiUrl + '/notifications', body);
  }

  getNotificationsWithValueChanges(userId: string): Observable<any[]> {
    const userDocRef = doc(this.fb, 'users', userId);
    const notificationsCollectionRef = collection(userDocRef, 'notifications');
    const notificationsQuery = queryFirestore(notificationsCollectionRef, orderBy('date', 'desc'));
  
    return collectionData(notificationsQuery, { idField: 'id' });
  }
}
