import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  success(message:string){
    return Swal.fire({
      text: message,
      icon: 'success',
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'btn-confirm-modal'
      }
    })
  }

  error(message:string){
    return Swal.fire({
      text: message,
      icon: 'error',
      allowOutsideClick: false,
      confirmButtonText: 'Aceptar',
      customClass: {
        confirmButton: 'btn-confirm-modal'
      }
    })
  }

  warning(message:string){
    return Swal.fire({
      text: message,
      icon: 'warning',
      allowOutsideClick: false
    })
  }

  info(message:string){
    return Swal.fire({
      text: message,
      icon: 'info',
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'btn-confirm-modal'
      }
    })
  }

  displayAlert(message:string, icon:any){
    return Swal.fire({
        text: message,
        icon,
        allowOutsideClick: false
      })
  }

  load(type = 'show'){
    if(type == 'show'){
      Swal.fire({
        title: 'Espere un momento...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(null)
        }
      })
    }else{
      Swal.close();
    }
  }

  confirm(text:string, title?: string, confirmText?:string, cancelText?:string, icon2?:any){
    return Swal.fire({
      title,
      text,
      icon: icon2 || 'info',
      showCancelButton: true,
      allowOutsideClick: false,
      cancelButtonText: cancelText || 'Cancelar',
      confirmButtonText: confirmText || 'Continuar',
      reverseButtons: true,
      customClass: {
        cancelButton: 'btn-cancel-confirmation',
        confirmButton: 'btn-accept-confirmation'
      }
    })
  }

  htmlDialog(options: any){
    return Swal.fire({
      title: options?.title,
      icon: options.icon || 'info',
      html: options?.template,
      showCloseButton: options?.closeIcon,
      allowOutsideClick: false,
      showConfirmButton: options?.showConfirmButton,
      customClass: {
        cancelButton: 'btn-cancel-confirmation',
        confirmButton: 'btn-accept-confirmation'
      }
    })
  }

  imageModal(options: any){
    return Swal.fire({
      showCloseButton: true,
      imageUrl: options?.imageUrl,
      imageWidth: options?.imageWidth,
      imageHeight: options?.imageHeight,
      showConfirmButton: false,
      heightAuto: true,
      allowOutsideClick: false
    })
  }
}