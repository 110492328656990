import { Injectable, inject } from '@angular/core';
import { Firestore, collectionData, collection, query, where, getDocs, addDoc, doc, updateDoc, docData, getDoc, setDoc } from '@angular/fire/firestore';
import {AngularFirestore} from '@angular/fire/compat/firestore'
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {

    // firestore: Firestore = inject(Firestore);

    constructor(private firestore: Firestore){}

    async retrieveCollection(table: string){
        const itemCollection = collection(this.firestore, table);
        const docs = await getDocs(itemCollection);
        return docs.docs.map(doc => doc.data());
    }

    // async retrieveCollectionByQuery(table: string){
    //     const itemCollection = collection(this.firestore, table);
    //     let q = query(itemCollection);
    //     q = query(q, where('email', '==', 'gdominguez@caranty.com'))
    //     const docs = await getDocs(q);
    //     return docs.docs.map(doc => doc.data());
    // }

    async retrieveCollectionByQuery(table: string, queryField: string, queryValue: any){
        const itemCollection = collection(this.firestore, table);
        let q = query(itemCollection);
        q = query(q, where(queryField, '==', queryValue))
        const docs = await getDocs(q);
        // return docs.docs.map(doc => doc.data());
        return docs.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
    }

    createRegister(table: string, data: any){
        const itemCollection = collection(this.firestore, table);
        return addDoc(itemCollection, data);
    }

    async createRegisterWithID(table: string, id: string, data: any): Promise<void> {
        try {
          const itemCollection = collection(this.firestore, table);
          const itemDocRef = doc(itemCollection, id); // Specify the document ID here
          await setDoc(itemDocRef, data); // Use setDoc to create/update the document with the given ID
          console.log('Document created/updated');
        } catch (error: unknown) {
          if (error instanceof Error) {
            console.error('Error creating document: ', error.message);
          } else {
            console.error('Unexpected error: ', error);
          }
        }
      }

    async updateRegister(id: string, data: any, collection: string) {
        const docRef = doc(this.firestore, "users", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const itemDoc = doc(this.firestore, `${collection}/${id}`);
            return updateDoc(itemDoc, { ...data });
        }else{
            return null;
        }
    }

    getDocumentData(collectionName: string, documentId: string): Observable<any> {
        const docRef = doc(this.firestore, `${collectionName}/${documentId}`);
        return docData(docRef);
    }

    getCollectionData(collectionName: string): Observable<any[]> {
        const collectionRef = collection(this.firestore, collectionName);
        return collectionData(collectionRef, { idField: 'id' });
    }

    updateRegisterWithSubCollection(collection: string, id: string, subcollection: string, subcollectionId: string, data: any): Promise<void> {
        const notificationDocRef = doc(this.firestore, `${collection}/${id}/${subcollection}/${subcollectionId}`);
        return updateDoc(notificationDocRef, data);
    }

    async updateCollection(idCollection: string, data: any, collection: string): Promise<void> { 
        const userDocRef = doc(this.firestore, `${collection}/${idCollection}`);
        return updateDoc(userDocRef, { ...data });
    }
}