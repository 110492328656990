export const DEFAULT_LANGUAGE = 'es';
export const ACTION_MODE = {
  RESET: 'resetPassword',
  VERIFY: 'verifyEmail'
};
export const NAME_REGEX =
  '[a-zA-ZáéíóúÁÉÍÚÓñÑüÜ ][a-zA-ZáéíóúñÁÉÍÚÓÑüÜ ]{0,256}$';
export const CURP_REGEX =
  '[a-zA-Z0-9]{18}';
export const RFC_REGEX =
  '[a-zA-Z0-9]{13}';
export const PLATE_REGEX =
  '{9}';
export const MESSAGE_REGEX =
  '[a-zA-ZáéíóúÁÉÍÚÓñÑüÜ? ,_-¿?!¡." ][a-zA-ZáéíóúñÁÉÍÚÓÑüÜ? ,_-¿?!¡." ]{0,256}$';
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX = '^[0-9]{10}$';
export const MIN_LENGTH_PASS = 8;
export const expiredDate = '^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/((19\d{2})|([2-9]\d{3}))$';
export const authToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1hcmlvQG1hcmlvLmNvbSIsInBhc3N3b3JkIjoiMTIzNDUiLCJpYXQiOjE2MDY0Mjk0NDN9.pSi_QJBH1Be8s_jWg_IUZogT8wAEvWUGNODDYLsRcD4';
export const carToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1hcmlvQG1hcmlvLmNvbSIsInBhc3N3b3JkIjoiMTIzNDUiLCJpYXQiOjE2MDQxMDAxODN9.rM49k9McJIKVwMF0-FrU8Q7_mK6aTK0jsaTQsn6cU0k';
export const wsToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1hcmlvQG1hcmlvLmNvbSIsInBhc3N3b3JkIjoiMTIzNDUiLCJpYXQiOjE2MDQxMDAxODN9.rM49k9McJIKVwMF0-FrU8Q7_mK6aTK0jsaTQsn6cU0k';
export const editCarToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1hcmlvQG1hcmlvLmNvbSIsInBhc3N3b3JkIjoiMTIzNDUiLCJpYXQiOjE2MDI1MzU3Nzl9.3CYaAcwyqnKRK3sgNf6FcaoXsDZDN-rCL_v2E6iJJOg';
export const sourceIdToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFydHVyb185NDA4QGljYXJhbnR5LmNvbSIsInBhc3N3b3JkIjoiMDEyMzQ1Njc4OSIsImlhdCI6MTYwODIyODI2MX0.6bXAUc4vGV0mz5JDceN6inqtuByZ8IEXbRrg5uYW7ho';
export const LETTERS_REGEX = '[a-zA-Z ]*';
export const CVV_REGEX = '[0-9]*';
export const URL_SEPOMEX = 'https://api-sepomex.hckdrk.mx/query';
export const COMPROBANTS_ARR = [
  'INE',
  'Pasaporte',
  'Cédula',
  'INAPAM',
  'FM2'
];
export const VOUCHERS_ARR = [
  'Agua',
  'Luz',
  'Teléfono',
  'Internet',
];
export const TENENCIAS_ARR = [
  '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010', '2009', '2008', '2007', '2006', '2005', '2004', '2003', '2002', '2001', '2000'
];

export const CAR_COLORS = [
  'Rojo',
  'Blanco',
  'Amarillo',
  'Azul',
  'Verde',
  'Negro',
  'Gris',
  'Plata',
  'Oro',
  'Otro'
];

export const TYPE_CARS_ARR = [
  {text: "SUV", selected: false},
  {text: "SEDAN", selected: false},
  {text: "PICK UP", selected: false},
  {text: "MINIVAN", selected: false},
  {text: "CROSSOVER", selected: false},
  {text: "HATCHBACK", selected: false},
  {text: "AUTO", selected: false}
];

export const OWNERS = [
  { name: 'Único', value: '1' },
  { name: '2', value: '2' },
  { name: '3', value: '3' },
  { name: '4', value: '4' },
  { name: 'Más de 4', value: '5' }
];

export const Types_Messages = {
  es: [
    {type: 'counterOffer', textBuyer: 'Contraoferta enviada', textSeller: 'Tienes una contraoferta', textNotification: 'Tienes una contraoferta'},
    {type: 'offert', textBuyer: 'Oferta enviada', textSeller: 'Tienes una oferta'},
    {type: 'question', textBuyer: 'Pregunta enviada', textSeller: 'Tienes una pregunta'},
    {type: 'confirmSellerInfo', textBuyer: 'Esperando confirmación del vendedor', textSeller: 'Confirma la venta', textNotification: 'Venta confirmada'},
    {type: 'refuse', textBuyer: 'Oferta rechazada', textSeller: 'Oferta rechazada', textNotification: 'Oferta rechazada'},
    {type: 'payProcess', textBuyer: 'Realiza el pago', textSeller: 'Realiza el pago', textNotification: 'Realiza el pago'},
    {type: 'acceptOffert', textBuyer: 'Oferta aceptada', textSeller: 'Oferta aceptada', textNotification: 'Oferta aceptada'},
    {type: 'newOffer', textBuyer: 'Oferta enviada', textSeller: 'Tienes una oferta'},
    {type: 'purchaseAccepted', textBuyer: 'Compra confirmada', textSeller: 'Compra confirmada', textNotification: 'Compra confirmada'}
  ]
}

export const ARMOR_LEVELS = [
  'Nivel II',
  'Nivel III',
  'Nivel III+',
  'Nivel IV',
  'Nivel IV+',
  'Nivel V',
  'Nivel VI',
  'Nivel VII',
  'Nivel VIII'
]

export const TRANSMISSIONS = [
  'AUTOMÁTICA',
  'MANUAL'
]

export const TIRE_CONDITIONS = [
  'Buenas', 
  'Medio', 
  'Malas'
]

export const KeywordsSEO = [
  "autos seminuevos",
  "carros usados",
  "venta de autos seminuevos",
  "comprar autos seminuevos",
  "financiamiento de autos seminuevos",
  "seminuevos con garantía",
  "autos seminuevos certificados",
  "autos seminuevos económicos",
  "ofertas de autos seminuevos",
  "autos seminuevos con financiamiento",
  "autos seminuevos {brand}",
  "autos seminuevos {brand} {model}",
  "{brand} {model} {year} seminuevo",
  "compra {brand} {model} seminuevo",
  "venta de {brand} {model} seminuevo"
]

export const TitleSEO = [
  "Venta de Autos Seminuevos - {brand} {model} {year} - Caranty",
  "{brand} {model} {year} en Venta - Autos Seminuevos - Caranty",
  "Autos Seminuevos a la Venta - {brand} {model} {year} - Caranty",
  "Caranty - {brand} {model} {year} - Venta de Autos Seminuevos",
  "{brand} {model} {year} - Venta de Autos Seminuevos en Caranty",
  "Encuentra Autos Seminuevos - {brand} {model} {year} - Caranty",
  "Venta de {brand} {model} {year} - Autos Seminuevos - Caranty",
  "Caranty - Venta de Autos Seminuevos {brand} {model} {year}",
  "{brand} {model} {year} - Compra Autos Seminuevos - Caranty",
  "Autos Seminuevos {brand} {model} {year} en Venta - Caranty",
  "Venta de {brand} {model} {year} Seminuevos Garantizados - Caranty",
  "{brand} {model} {year}: Seminuevo, Garantizado y a la Venta en Caranty"
]

export const DescriptionsSEO = [
  "Descubre el {brand} {model} {year} en venta en Caranty. Gran selección de autos seminuevos. Simula tu crédito",
  "Compra tu {brand} {model} {year} en Caranty. Encuentra una amplia variedad de autos seminuevos. Adquiere tu auto ideal.",
  "Encuentra el {brand} {model} {year} perfecto en Caranty. Gran selección de autos seminuevos disponibles. Compra tu próximo auto hoy",
  "{brand} {model} {year} en venta en Caranty. Explora nuestra amplia selección de autos seminuevos. Encuentra tu auto ideal.",
  "Compra un {brand} {model} {year} en Caranty. Amplia gama de autos seminuevos. Simula y encuentra el auto perfecto para ti.",
  "Encuentra tu {brand} {model} {year} en Caranty. Amplia selección de autos seminuevos disponibles.",
  "{brand} {model} {year} en Caranty. Gran variedad de autos seminuevos. Encuentra tu auto ideal hoy mismo.",
  "Descubre el {brand} {model} {year} en Caranty. Amplia gama de autos seminuevos disponibles. Adquiere tu próximo auto.",
  "{brand} {model} {year}: seminuevo, financiado y a tu disposición en Caranty. Encuentra el auto de tus sueños al mejor precio.",
  "¡{brand} {model} {year} seminuevo te espera en Caranty! Amplia selección y financiamiento disponible. ¡Encuentra tu próximo auto hoy!"
]