import { Injectable, Inject, PLATFORM_ID, ElementRef } from '@angular/core';
import { BehaviorSubject, Observable, from, fromEvent, lastValueFrom } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/compat/firestore';
// import { LoginComponent } from '../auth/login/login.component';
// import { LoginV2Component } from '../auth/login-v2/login-v2.component';
import { debounceTime } from 'rxjs/operators';
import { map } from 'rxjs';
import { ViewportScroller, isPlatformBrowser, isPlatformServer } from '@angular/common';
// import { Types_Messages} from '../data/data';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// import { IResultAzure } from '../interfaces/response-api-azure.interface';
import { Router } from '@angular/router';
// import { IFileUpload } from '../interfaces/index-interfaces';
// import { deleteField, doc, updateDoc } from 'firebase/firestore';
import { Firestore, docData, getDoc, deleteField, doc, updateDoc } from '@angular/fire/firestore';
// import { IResultGeocoding } from '../interfaces/geocode.interface';
import { IResultAzure } from '@app/core/interfaces/http.interface';
import { FirebaseService } from './firebase.service';
import {Types_Messages} from '@app/shared/data/data'

@Injectable({
  providedIn: 'root'
})

export class UtilsService {

  public isLoading = new BehaviorSubject(false);
  loadingSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**
   * Contains in-progress loading requests
   */
  loadingMap: Map<string, boolean> = new Map<string, boolean>();
  messages: any = Types_Messages;
  private apiUrl = environment.endPoint;
  private azureKey = environment.azureApiKey;
  resizeObservable$!: Observable<Event>
  onlineStatus!: Observable<Event>;
  offlineStatus!: Observable<Event>;
  reloadObservable!: Observable<Event>;
  scrollObs$!: Observable<Event>;
  keyboardObs$!: Observable<Event>;
  public currentRoute?: string;
  private urlApi = environment.endPoint;

  constructor(private dialog: MatDialog, 
              // private db:AngularFirestore,
              @Inject(PLATFORM_ID) private platformId: string,
              private translateService: TranslateService,
              private http: HttpClient,
              private router: Router,
              private firestore: Firestore,
              private viewportScroller: ViewportScroller
              /*private firebaseService: FirebaseService*/) { 
                if(isPlatformBrowser(this.platformId)){
                  this.resizeObservable$ = fromEvent(window, 'resize').pipe(debounceTime(100));
                  this.onlineStatus = fromEvent(window, 'online');
                  this.offlineStatus = fromEvent(window, 'offline');
                  this.reloadObservable = fromEvent(window, 'beforeunload');
                  this.scrollObs$ = fromEvent(window, 'scroll');
                  this.keyboardObs$ = fromEvent(window, 'keydown');
                }
              }

  setLoading(loading: boolean, url: string): void {
    if (!url) {
      throw new Error('The request URL must be provided to the LoadingService.setLoading function');
    }
    if (loading === true) {
      this.loadingMap.set(url, loading);
      this.loadingSub.next(true);
    }else if (loading === false && this.loadingMap.has(url)) {
      this.loadingMap.delete(url);
    }
    if (this.loadingMap.size === 0) {
      this.loadingSub.next(false);
    }
  }

  openLoginDialog(url?:string, params?: any){
    url = url ? url : this.currentUrl;
    const config: MatDialogConfig<any> = {
      panelClass: 'ic-login-container',
      autoFocus: false,
      disableClose: true,
      data: {
        redirect: url,
        params
      }
    };
    
    // return this.dialog.open(LoginV2Component, config);
  }

  openDialog(component:any, config: MatDialogConfig){
    return this.dialog.open(component, config);
  }

  /**
   * @summary Metodo para obtener el numero de paginas 
   * @param totalItems 
   * @param currentPage 
   * @param pageSize 
   * @author GADC 30/12/2021
   */
  getPager(totalItems: number, currentPage = 1, pageSize: number) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  getResourcesById(docId:string): Observable<any> {
    // return this.db.collection('resources').doc(docId).valueChanges()
    const booksRef = doc(this.firestore, `resources/${docId}`);
    return docData(booksRef, {idField: 'values'}) as Observable<any[]>;
  }

  getShowroom(id: string): Observable<any> {
    const showroomDoc = doc(this.firestore, 'showrooms', id);
    return from(getDoc(showroomDoc)).pipe(
      map((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          const id = snapshot.id;
          return { id, ...data as object };
        } else {
          return null; // Handle the case where the document does not exist
        }
      })
    );
  }

  getShowroomsWithApi(){
    return this.http.get<any>(`${this.apiUrl}/showrooms?type=azure`);
  }

  getPartners(partner: string): Observable<any> {
    const partherDoc = doc(this.firestore, 'partners', partner);
    return from(getDoc(partherDoc)).pipe(
      map((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          const id = snapshot.id;
          return { id, ...data as object };
        } else {
          return null; // Handle the case where the document does not exist
        }
      })
    );
  }

  get isBrowser(){
    return isPlatformBrowser(this.platformId)
  }

  get isServer(){
    return isPlatformServer(this.platformId);
  }

  public getPercentajesSantander(idCollection: string) {
    const paymentDoc = doc(this.firestore, 'downpayment', idCollection);
    return from(getDoc(paymentDoc)).pipe(
      map((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          const id = snapshot.id;
          return { id, ...data as object };
        } else {
          return null; // Handle the case where the document does not exist
        }
      })
    );
  }

  getBrowserName() {
    let browser = '';
    if (isPlatformBrowser(this.platformId)) {
      const agent = window.navigator.userAgent.toLowerCase();
      switch (true) {
        case agent.indexOf('edge') > -1:
          browser = 'edge';
          break;
        case agent.indexOf('edg') > -1:
          browser = 'edge-mobile';
          break;
        case agent.indexOf('opr') > -1 && !!(<any>window).opr:
          browser = 'opera';
          break;
        case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
          browser = 'chrome';
          break;
        case agent.indexOf('trident') > -1:
          browser = 'ie';
          break
        case agent.indexOf('firefox') > -1:
          browser = 'firefox';
          break;
        case agent.indexOf('safari') > -1:
          browser = 'safari';
          break;
        default:
          browser = 'other';
      }
    }
    return browser;
  }

  getMessageNotification(type: string){
    const lang = environment.language;
    return this.messages[lang].filter((e: any) => e.type == type);
  }

  getTranslateData(key: string){
    return this.translateService.get(key);
  }

  get browserSupportWebp() {
    if(isPlatformBrowser(this.platformId)){
      const elem = document.createElement('canvas');
      if (elem.getContext && elem.getContext('2d')) {
        // was able or not to get WebP representation
        return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
      }
      else {
        // very old browser like IE 8, canvas not supported
        return false;
      }
    }
    return '';
  }

  getShowrooms(){
    const url = `${this.apiUrl}/showroom/getShowRoomsStates`

    return this.http.get(url);
  }

  getShowroomsByState(id:string){
    const url = `${this.apiUrl}/showroom/getShowRoomsByState?state=${id}`

    return this.http.get(url);
  }

  getShowroomsArray(){
    const url = `${this.apiUrl}/showroom/getShowRoomsArray`;

    return this.http.get(url);
  }

  forceReloadComponent(url: string){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([url]);
  }

  getResourcesWithApi() {
    return this.http.get<IResultAzure>(`${this.apiUrl}/resources?type=azure`);
  }

  getParametersWithApi() {
    return this.http.get<IResultAzure>(`${this.apiUrl}/parameters?type=azure`);
  }

  getParametersWithApiPromise() {
    return this.http.get<IResultAzure>(`${this.apiUrl}/parameters?type=azure`).toPromise();
  }

  getParthers(){
    return this.http.get<IResultAzure>(`${this.apiUrl}/partners?type=azure`);
  }

  uploadFiles(body: any){
    const url = `${this.apiUrl}/web/storage/loadimage?type=azure`;

    return this.http.post(url, body, {reportProgress: true, observe: 'events'});
  }

  uploadDocuments(body: any){
    const url = `${this.apiUrl}/v2/mesa-control/documentos?type=azure`;

    return this.http.post<IResultAzure>(url, body, {reportProgress: true, observe: 'events'});
  }

  deleteFilesCar(body: any){
    const params = new HttpParams().set('code', this.azureKey);
    const url = `${this.apiUrl}/web/storage/loadimage?type=azure`;

    return this.http.put<IResultAzure>(url, body, {params});
  }

  deletePhysicalCar(body: any){
    const url = `${this.apiUrl}/clone-65f9c/web/storage/loadimage?code=IeITEeTN7UQzHPbjTGoO139sWRnEKZCb5N7F36p4guUiwbvMt0mmaA==&type=azure`;

    return this.http.request('DELETE', url, {body});
  }
  
  get currentUrl(){
    return this.currentRoute as string;
  }

  set currentUrl(url: string){
    this.currentRoute = url;
  }

  updateFirebaseCollectionData(collection: string, id: string, data: any) {
    const docRef = doc(this.firestore, collection, id);
    return updateDoc(docRef, data);
  }

  async deletePropertyCollection(collection: string, id:string, property: string){
    const collectionRef = doc(this.firestore, collection, id);
    await updateDoc(collectionRef, {[property]: deleteField()})
  }

  getGeocodeDirections(lat: number, lng: number, address:string){
    const params = new HttpParams().set('address', address).set('key', 'AIzaSyBf-O_xU5v5gy268YHcqqoyRk3W8koRyJo');
    const url = 'https://maps.googleapis.com/maps/api/geocode/json?type=default';

    return this.http.get<any>(url, {params});
  }

  getLocalJson(path:string){
    return this.http.get(path);
  }

  getContactCenterInfo(){
    const url = `${this.apiUrl}/graph/usuarios?type=azure`;

    return this.http.get<IResultAzure>(url);
  }

  capitalizeFirstLetter(text: string) {
    const str_arr = text.split(' ')

    for(let i = 0; i < str_arr.length; i++){
      str_arr[i] = str_arr[i][0].toUpperCase() + str_arr[i].slice(1)
    }
    return str_arr.join(' ');
  }
  
  getRandomValueFromArray(array: Array<any>) {
    if (array.length === 0) {
      return undefined; // or throw an error if you prefer
    }

    // Generate a random index based on the array's length
    const randomIndex = Math.floor(Math.random() * array.length);

    // Return the value at the random index
    return array[randomIndex];
  }

  removeRepeatedWords(input: string) {
    const words = input.split(/\s+/);
    const uniqueWords = new Set();
    const filteredWords = words.filter(word => {
      if (uniqueWords.has(word)) {
        return false;
      } else {
        uniqueWords.add(word);
        return true;
      }
    });

    return filteredWords.join(' ');
  }

  /**
   * función que retorna un array a partir de un string separado por comas
   * @param result string
   * @returns array
   * @author GADC 17/01/2022
   */
  stringToArray(result: string, replaceWhiteSpaces = true) {
    let arrayCities: string[] = [];
    if (result) {
      result = replaceWhiteSpaces ? result.replace(' ', '') : result;
      arrayCities = result.split(',');
      if (arrayCities.length) {
        arrayCities = arrayCities.map(v => v.trim())
      }
    }
    return arrayCities;
  }

  getBlogs() {
    const url = `${this.urlApi}/blogpasado?type=azure`;

    return this.http.get<IResultAzure>(url);
  }

  getBlogById(id?: string) {
    const url = `${this.urlApi}/blog?${id}&type=azure`;

    return this.http.get<IResultAzure>(url);
  }

  /**
   * función que retorna el index de un array en base a una llave
   * @param key 
   * @param value 
   * @param array 
   * @author GADC 11/01/2022
   */
  getIndexOfArray(key:string, value: any, array:Array<any>) {
    let arrayValue: any;
    if(key && value && array.length){
      const fIndex = (item: any) => item[key] == value;
      arrayValue = array.findIndex(fIndex);
    }
    return arrayValue
  }

  convertCurrency(value: string) {
    // Remove any currency symbols, commas, and extra spaces
    const cleanedInput = value
      .replace(/[^\d.-]/g, '') // Remove non-numeric characters except dots and minus signs
      .replace(/,/g, ''); // Remove commas
    
    // Convert the cleaned string to a number
    const numberValue = parseFloat(cleanedInput);
  
    // Check if the conversion was successful; otherwise, return NaN
    if (isNaN(numberValue)) {
      console.error('Invalid input: Unable to convert to a number');
      return NaN;
    }
  
    // Assuming the input is already in Mexican Pesos; otherwise, conversion logic can be added here.
    return numberValue;
  }

  public setScrollToElement(idElement: ElementRef){
    if(idElement){
      idElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    }
  }

  checkSupportWebp() {
    let isValid = false;
    const elem = document.createElement('canvas');
    if (elem.getContext && elem.getContext('2d')) {
      // was able or not to get WebP representation
      isValid = elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
    }
    else {
      // very old browser like IE 8, canvas not supported
      isValid = false;
    }
    return isValid;
  }

  parseCurrency = (value: number) => Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);

  resetScrollPosition(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  getJournals() {
    const url = `${this.apiUrl}/comunicadodeprensa?type=azure`;
    return this.http.get(url);
  }

  getPayments(idUser: string) {
    const url = `${this.apiUrl}/user/getUser/generalInfo/${idUser}?request=caranty&flag=conceptos_pago`;
    return this.http.get<any>(url);
  }

  updatePaymentStatus(data: any) {
    const url = `${this.apiUrl}/user/payments/concepto/${data.id_payment}?id_concepto=${data.id_concepto}`;
    const body = { reembolso: data.reembolso }
    return this.http.put(url, body);
  }
}