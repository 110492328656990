import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Auth, User, user, authState, signInAnonymously, signInWithEmailAndPassword, signOut, fetchSignInMethodsForEmail, createUserWithEmailAndPassword, getAuth, updateProfile, onAuthStateChanged, signInWithPopup, signInWithRedirect, GoogleAuthProvider, FacebookAuthProvider, UserCredential, AdditionalUserInfo, getAdditionalUserInfo, getRedirectResult, signInWithCredential, OAuthCredential, OAuthProvider, AuthErrorCodes, sendPasswordResetEmail, confirmPasswordReset } from '@angular/fire/auth';
import { Observable, Subject, catchError, lastValueFrom, map, of, takeUntil, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IRegister, IResultSignInWithEmail } from '../interfaces/auth.interface'
import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Firestore, collection, addDoc, query, where, getDocs, doc, getDoc, docData} from '@angular/fire/firestore';
// import { FirebaseService } from './firebase.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    // private auth = inject(Auth);
    private readonly apiToken = environment.carantyApi.token;
    private readonly tokenKey = environment.carantyApi.keyToken;
    private readonly apiRefreshToken = environment.carantyApi.refreshToken;
    private readonly apiUrl = environment.endPoint;
    private _http: HttpClient;
    // private usersCollection: CollectionReference;
    googleCredentials: any;
    facebookCredentials: any;
    appleCredentials: any;

    constructor(private auth: Auth, 
                @Inject(PLATFORM_ID) private platformId: string,
                private httpBackend: HttpBackend,
                private firestore: Firestore,
                // private fireService: FirebaseService,
                private router: Router,
                private http: HttpClient,){
        this._http = new HttpClient(httpBackend);
    }

    get user() {
        return user(this.auth);
    }

    get authState(){
        return authState(this.auth)
    }

    get currentUser() {
        return this.auth.currentUser;
    }

    isAuthenticated(): Observable<boolean> {
        return authState(this.auth).pipe(
            map(user => !!user) // Returns true if user is logged in, false otherwise
        );
    }

    public async signIn(email: string, password: string): Promise<any> {
        try {
            const { user } = await signInWithEmailAndPassword(this.auth, email, password);
            const subToken = new Subject();
            (await this.generateToken(email, password))
                .pipe(takeUntil(subToken))
                .subscribe({
                    next: (result:any) => {
                        if (result && isPlatformBrowser(this.platformId)) {
                            const tokenLocalStorage = result.idToken;
                            localStorage.removeItem('token');
                            localStorage.setItem('refreshToken', result.refreshToken);
                            localStorage.setItem('token', tokenLocalStorage);
                            subToken.next(true);
                            subToken.complete();
                            // this.subsService.sendSessionStatus(true);
                        }
                    },
            })
            return user;
        } catch (error) {
            return error;
        }
    }

    public async generateToken(email: string, password: string) {
        const data = {
            email,
            password,
            returnSecureToken: true
        }
        
        const url = `${this.apiToken}?key=${this.tokenKey}`
        return this._http.post(url, data, { headers: {'content-type': 'application/json'} });
    }

    async verifyEmailExist(email: string) {
        try {
            return await fetchSignInMethodsForEmail(this.auth, email);
        } catch (error) {
            return error;
        }
    }

    async signUp(userForm: IRegister, applySantander = false, options: any): Promise<any> {
        try {
            const { email, password, name } = userForm;
            const { user } = await createUserWithEmailAndPassword(this.auth, email, password);
            updateProfile(user, {displayName: name}).then(async () => {
                this.signIn(email, password);
                this.registerUserToFirebase(userForm, user.uid, applySantander, options);
            });
        } catch (error) {
            return error;
        }
    }

    async registerUserToFirebase(userData: IRegister, userId: string, applySantander = false, options: any): Promise<any> {
        try {
            if (userData && userId) {
                const data = {
                    email: userData.email.toLowerCase().trim(),
                    name: userData.name,
                    phone: userData.phone,
                    role: userData.role,
                    firstLastName: userData.firstLastName,
                    status: userData.status,
                    createdAt: userData.createdAt,
                    balance: userData.balance,
                    addInfo: userData.addInfo,
                    env: 'web'
                };
                if (applySantander) Object.assign(data, { 'referencia': 'santander' })
                // await this.fireService.createRegister('users', data);
                // await this.fire.collection('users').doc(userId).set(data);
                const userInfo: any = await this.getUserByEmail(data.email);
                if ([null, undefined, ''].includes(userInfo)) {
                    // this.storageService.addData('register', data, 'form');
                    sessionStorage.setItem('idUser', userId);
                    // this.alertService.error('Ocurrió un error al realizar el registro, intente nuevamente').then(confirm => {
                    //     if (confirm.isConfirmed) this.logOut('/registro');
                    // })
                } else {
                    this.signIn(userData.email, userData.password);
                    sessionStorage.removeItem('idUser');
                    // this.storageService.deleteData('register', 'form');
                    if (options) {
                        const { applyRedirect, urlRedirect } = options;
                        if (applyRedirect && urlRedirect) {
                            setTimeout(() => {
                                this.router.navigate([urlRedirect]);
                            }, 1500);
                        }
                    }
                }
            }
        } catch (error) {
            // this.alertService.error(error.message);
        }
    }

    async getUserByEmail(email: string) {
        const itemCollection = collection(this.firestore, 'users');
        let fetchQuery = query(itemCollection);
        fetchQuery = query(fetchQuery, where('email', '==', email))
        const docs = await getDocs(fetchQuery);
        // return docs.docs.map(doc => doc.data())[0];
        const userDoc = docs.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))[0];

        return userDoc;
    }

    getUserByID(documentId: string): Observable<any> {
        const docRef = doc(this.firestore, `users/${documentId}`);
        return docData(docRef);
    }

    async validatePhone(phone: any) {
        const itemCollection = collection(this.firestore, 'users');
        let fetchQuery = query(itemCollection);
        fetchQuery = query(fetchQuery, where('phone', '==', phone))
        const docs = await getDocs(fetchQuery);
        const docsInfo = docs.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }))[0];
        return docsInfo;
    }

    sendOTPCode(data: any) {
        return this.http.post(`${this.apiUrl}/twilio/send?type=twilio`, data);
    }

    validateOTPCode(data: any) {
        return this.http.post(`${this.apiUrl}/twilio/verify?type=twilio`, data);
    }

    tokenExpired(token: string) {
        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        return (Math.floor((new Date).getTime() / 1000)) >= expiry;
    }

    refreshToken() {
        const refresToken = localStorage.getItem("refreshToken") as string;
        const params = new HttpParams()
            .set('key', this.tokenKey)
            .set('grant_type', 'refresh_token')
            .set('refresh_token', refresToken)
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        return this.http.post(`${this.apiRefreshToken}/token?`, null, { headers, params })
            .pipe(tap((data: any) => {
                localStorage.setItem('token', data.id_token)
            }), catchError((err) => {
                console.log(err)
                return of(false)
            }));
    }

    async validateUserInfo() {
        return new Promise((resolve, reject) => {
            const auth = getAuth();
            onAuthStateChanged(auth, async (user) => {
                console.log(user)
                if (user) {
                    const docRef = doc(this.firestore, "users", user.uid);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        const userData = docSnap.data();
                        if (userData && typeof userData === 'object' && Object.keys(userData).length > 1) {
                            const { name, email, phone, firstLastName } = userData;
                            if (!name || !email || !phone) {
                                this.logOut('/', true);
                                // this.subsService.destroyUserInformation();
                                // this.subsService.sendUserDataProvider({ name, phone, firstLastName, email, id: user.uid });
                                resolve(false);
                                setTimeout(() => {
                                    this.router.navigate(['/auth/update']);
                                }, 500);
                            }
                            resolve(true);
                        }
                    } else {
                        // docSnap.data() will be undefined in this case
                        resolve(false);
                        console.log("No such document!");
                    }
                }
            });
        })
    }

    async logOut(url: string, preventRedirect = false): Promise<any> {
        try {
            if (isPlatformBrowser(this.platformId)) {
                localStorage.clear();
                sessionStorage.removeItem('garantyToken');
                sessionStorage.removeItem('currentPage');
            }
            await this.auth.signOut();
            // await this.afAuth.signOut();
            // this.currentUser$.next(null);
            // this.currentUser$.complete();
            if (!preventRedirect) this.router.navigate([url]);
        } catch (error) {
            // console.error(error.message);
        }
    }

    async signInWithGoogle(): Promise<{ userCredential: UserCredential, additionalUserInfo: AdditionalUserInfo | null } | null> {
        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(this.auth, provider);
            this.googleCredentials = GoogleAuthProvider.credentialFromResult(result);
            // Use getAdditionalUserInfo to extract additionalUserInfo
            const additionalUserInfo = getAdditionalUserInfo(result);
            return {
                userCredential: result,
                additionalUserInfo
            };
        } catch (error) {
            console.error('Error in Google sign-in:', error);
            return null;  // Handle errors
        }
    }

    async signInSocialMedia(type: 'google' | 'apple' | 'facebook'): Promise<{ userCredential: UserCredential, additionalUserInfo: AdditionalUserInfo | null } | string> {
        try {
            const provider = this.getProvider(type);
            const result = await signInWithPopup(this.auth, provider);
            if(type === 'google') this.googleCredentials = GoogleAuthProvider.credentialFromResult(result);
            if(type === 'facebook') this.facebookCredentials = FacebookAuthProvider.credentialFromResult(result);
            if(type === 'apple') this.appleCredentials = OAuthProvider.credentialFromResult(result);
            // Use getAdditionalUserInfo to extract additionalUserInfo
            const additionalUserInfo = getAdditionalUserInfo(result);
            return {
                userCredential: result,
                additionalUserInfo
            };
        } catch (error) {
            return this.handleAuthError(error);  // Handle errors
        }
    }

    getProvider(type: string) {
        let provider: any = null;
        if (type === 'google') provider = new GoogleAuthProvider();
        if (type === 'facebook') provider = new FacebookAuthProvider();
        if (type === 'apple') provider = new OAuthProvider('apple.com');
        return provider;
    }

    handleAuthError(error: any): string {
        let message = '';
        switch (error.code) {
            case AuthErrorCodes.INVALID_EMAIL:
                console.error('Invalid email format.');
                message = 'Formato invalido';
                break;
            case AuthErrorCodes.USER_DISABLED:
                console.error('User account is disabled.');
                message = 'La cuenta no existe o esta desactivada';
                break;
            case AuthErrorCodes.POPUP_CLOSED_BY_USER:
                console.error('The popup has been closed by the user before completing sign-in.');
                message = 'No se recibió una confirmación de inicio de sesión';
                break;
            case AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE:
                console.error('The account already exists with a different credential.');
                message = 'El correo ya esta en uso con diferente credenciales';
                break;
            default:
                console.error('Unknown error during sign-in:', error.message);
                message = error.message;
        }
        return message;
    }

    async signInWithProvider(provider: 'google' | 'apple' | 'facebook', urlRedirect: string) {
        try {
            let credentials: OAuthCredential;
            if (provider === 'facebook') {
                credentials = this.facebookCredentials;
            } else if (provider === 'apple') {
                credentials = this.appleCredentials;
            } else if (provider === 'google') {
                credentials = this.googleCredentials;
            } else {
                throw new Error('Unsupported provider');
            }
            await signInWithCredential(this.auth, credentials);
            this.router.navigate([urlRedirect])
        } catch (error) {
            console.error(`Error during ${provider} sign-in:`, error);
        }
    }

    validatePhoneNumber(email: string) {
        const headers = {
            'api-key': environment.apiKey
        };
        return this.http.get<any>(`${this.apiUrl}/user/v1/checkUserPhone?email=${email}`, { headers });
    }

    async resetPassword(email: string): Promise<string> {
        try {
            await sendPasswordResetEmail(this.auth, email);
            return 'success'
        } catch (error: unknown) {
            if (error instanceof Error) {
                console.error('Error resetting password: ', error.message);
            } else {
                console.error('Unexpected error', error);
            }
            return 'fail';
        }
    }

    public setNewPassword(code: any, password: any): Promise<any> {
        return confirmPasswordReset(this.auth, code, password);
    }
}