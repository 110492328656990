import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as Actions from '@app/store/actions.actions';
import { IStoreFilters, TypesFilter } from '@app/core/interfaces';

@Injectable({
    providedIn: 'root'
})
export class StoreService {

    constructor(private store: Store) {}

    dispatchSearchFilter(type: TypesFilter) {
        this.store.dispatch(Actions.searchFilter({typeFilter: type}));
    }

    dispatchAddFilter(itemFilter: IStoreFilters) {
        this.store.dispatch(Actions.addFilter({itemFilter}));
    }

    dispatchRemoveFilter(filterId: string) {
        this.store.dispatch(Actions.removeFilter({id: filterId}));
    }

    dispatchRemoveFilterByType(type: TypesFilter) {
        this.store.dispatch(Actions.removeFilterByType({typeFilter: type}));
    }

    dispatchAddParameters(parameters: any) {
        this.store.dispatch(Actions.addParameters({parameters}));
    }
}