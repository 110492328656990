import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { loadFilteredData, updateFilter, updatePage } from "@app/store/filters/filters.actions";
import { Store } from "@ngrx/store";
import { TypesFilter } from "../interfaces";
import { UtilsService } from "./utils.service";

@Injectable({
    providedIn: 'root'
})
export class FilterService {
    
    constructor(private router: Router, private route: ActivatedRoute, private store: Store, private utilsService: UtilsService) { }

    saveFiltersToLocalStorage(filters: any, page: number) {
        const filterData = { filters, page };
        if(this.utilsService.isBrowser) localStorage.setItem('activeFilters', JSON.stringify(filterData));
    }

    loadFiltersFromLocalStorage() {
        if (this.utilsService.isBrowser) {
            const filterData = sessionStorage.getItem('filters');
            const page = sessionStorage.getItem('page');
            if (filterData) {
                const filters = JSON.parse(filterData);
                for (const key in filters) {
                    if (filters.hasOwnProperty(key)) {
                        this.store.dispatch(updateFilter({
                            filter: {
                                key: key as TypesFilter, 
                                value: filters[key].value,
                                description: filters[key].description,
                                display: filters[key].display
                            }
                        }));
                    }
                }
                this.store.dispatch(updatePage({ page: +(page ?? 1) }));
                this.store.dispatch(loadFilteredData());
            }
        }
    }

    updateQueryParams(filters: any, page: number) {
        const queryParams = { ...filters, page };
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: queryParams,
            queryParamsHandling: 'merge',
        });
        // this.saveFiltersToLocalStorage(filters, page); // Guardar en localStorage
    }
}
