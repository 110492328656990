import { Inject, Injectable, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class MetaService {

    constructor(private title: Title, 
                private meta: Meta,
                private rendererFactory: RendererFactory2,
                @Inject(DOCUMENT) private document:Document){}

    updateTitle(title: string) {
        if (title) {
            this.title.setTitle(title);
        }
    }

    updateDescription(description: string) {
        if (description) {
            this.meta.updateTag({ name: 'description', content: description });
        }
    }

    updateKeywords(keys: string){
        if(keys){
            this.meta.updateTag({ name: 'keywords', content: keys })
        }
    }

    updateRobots(description: string) {
        if (description) {
            this.meta.updateTag({ name: 'robots', content: description });
        }
    }

    updateCanonicalUrl(url: string) {
        const head = this.document.getElementsByTagName('head')[0];
        let element: HTMLLinkElement = this.document.querySelector(`link[rel='canonical']`) as HTMLLinkElement;
        if (element == null) {
            element = this.document.createElement('link') as HTMLLinkElement;
            head.appendChild(element);
        }
        element.setAttribute('rel', 'canonical')
        element.setAttribute('href', url)
    }

    updateSocialTags(data: any){
        for(const item of data){
            this.meta.updateTag(item);
        }
    }

    addTag(tag: LinkDefinition, forceCreation?: boolean) {

        try {
            const renderer = this.rendererFactory.createRenderer(this.document, {
                id: '-1',
                encapsulation: ViewEncapsulation.None,
                styles: [],
                data: {}
            });

            const link = renderer.createElement('link');
            const head = this.document.head;
            const selector = this._parseSelector(tag);

            if (head === null) {
                throw new Error('<head> not found within DOCUMENT.');
            }

            Object.keys(tag).forEach((prop: string) => {
                return renderer.setAttribute(link, prop, tag[prop]);
            });

            // [TODO]: get them to update the existing one (if it exists) ?
            renderer.appendChild(head, link);

        } catch (e) {
            console.error('Error within linkService : ', e);
        }
    }

    private _parseSelector(tag: LinkDefinition): string {
        // Possibly re-work this
        const attr: string = tag.rel ? 'rel' : 'hreflang';
        return `${attr}="${tag[attr]}"`;
    }
}

export declare type LinkDefinition = {
    charset?: string;
    crossorigin?: string;
    href?: string;
    hreflang?: string;
    media?: string;
    rel?: string;
    rev?: string;
    sizes?: string;
    target?: string;
    type?: string;
} & {
        [prop: string]: string;
    };