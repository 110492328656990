import { createAction, props } from '@ngrx/store';
import { IStoreFilters, TypesFilter } from '@app/core/interfaces';

export const addFilter = createAction('[Filter] Add Item', props<{itemFilter: IStoreFilters}>());
export const removeFilter = createAction('[Filter] Remove Item', props<{id: string}>());
export const removeFilterByType = createAction('[Filter] Remove Item by Type', props<{typeFilter: TypesFilter}>());
export const searchFilter = createAction('[Filter] Search Item', props<{typeFilter: TypesFilter}>());
export const clearFilters = createAction('[Filter] Clear Filters');

export const addParameters = createAction('[App] Add Parameters', props<{parameters: any}>());
export const getParameters = createAction('[App] Get Parameters', props<{parameters: any}>());

export const addCarValues = createAction('[Sell] Car Data', props<{details: any}>());
export const addPrice = createAction('[Sell] Car Data', props<{price: any}>());
export const getCarValues = createAction('[Sell] Get Car Data', props<{data: { car: any; price: number; pictures: any }}>());


///////////////////////////
export const saveForm1Data = createAction(
    '[Forms] Save Form 1 Data',
    props<{ data: { [key: string]: any } }>()
  );
  
  export const saveForm2Data = createAction(
    '[Forms] Save Form 2 Data',
    props<{ data: { [key: string]: any } }>()
  );
  
  export const saveForm3Data = createAction(
    '[Forms] Save Form 3 Data',
    props<{ data: { [key: string]: any } }>()
  );