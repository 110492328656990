import { createAction, props } from '@ngrx/store';
import { IFilters } from './filters';

export const applyFilter = createAction(
  '[Filter] Apply Filter',
  props<{ filters: any }>()
);

export const updateFilter = createAction(
    '[Filter] Update Filter',
    props<{ filter: IFilters }>()
);

export const loadFilteredData = createAction(
    '[Filter API] Load Filtered Data'
);

export const loadFilteredDataSuccess = createAction(
    '[Filter API] Load Filtered Data Success',
    props<{ data: any, total: number }>()
);

export const loadFilteredDataFailure = createAction(
    '[Filter API] Load Filtered Data Failure',
    props<{ error: string }>()
);

export const updatePage = createAction(
    '[Filter] Update Page',
    props<{ page: number }>()
);

export const removeFilter = createAction(
    '[Filter] Remove Filter',
    props<{ key: string }>()  // Acción para eliminar un filtro específico
);  

export const clearFilters = createAction(
    '[Filter] Clear Filters'
)

export const setFilters = createAction(
    '[Filter] Set Filters',
    props<{ filters: IFilters }>()
);