import { EventEmitter, Inject, Injectable, PLATFORM_ID, isDevMode } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// import { carToken } from '../data/data';
import { map } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { isPlatformBrowser } from '@angular/common';
import { IResultAzure } from '../interfaces/http.interface';
// import { IRequest } from '../interfaces/find-my-car.interface';
// import { IRegisterVisit } from '../interfaces/register-visit.interface';
// import { IQuote } from '../interfaces/quote-price.interface';
// import { IResponseCars, IResultImages } from '../interfaces/car-details.interface';
import { lastValueFrom, Observable } from 'rxjs';
// import { IResponseQuoter } from '../interfaces/response-quoter.interface';
import { doc } from 'firebase/firestore';
import { collection, deleteDoc, docData, Firestore, getDoc, getDocs, query, updateDoc, where } from '@angular/fire/firestore';
// import { IProspect } from '../interfaces/prospects.interface';

@Injectable({
  providedIn: 'root'
})

export class CarsService {

  // public bannerAux: boolean;
  public headerEvent$ = new EventEmitter<any>();
  public bannerEvent$ = new EventEmitter<boolean>();
  public editCarEvent$ = new EventEmitter<boolean>();
  public goToEval$ = new EventEmitter<boolean>();
  public goToQuestions$ = new EventEmitter<boolean>();

  public goStep$ = new EventEmitter<number>();
  public step2$ = new EventEmitter<boolean>();
  public step3$ = new EventEmitter<boolean>();
  public step4$ = new EventEmitter<boolean>();
  public step5$ = new EventEmitter<boolean>();

  public goStepConfirmDelivery$ = new EventEmitter<number>();
  public step2ConfirmDelivery$ = new EventEmitter<boolean>();

  public changeBuyUrl$ = new EventEmitter<string>();

  public setCopyLink$ = new EventEmitter<boolean>();

  private apiUrl = environment.endPoint;
  private azureKey = environment.azureApiKey;
  private firebaseURL = environment.authService.authTokenUrl;
  // private token = carToken;

  public httpHeaders = new HttpHeaders({
    'content-type': 'application/json',
    Authorization: `Bearer `
  });

  headersCRM = new HttpHeaders({
    'x-functions-key': 's92yM890f4aCI/wmtdOIKX4yCPXuaaclgfSJJtBpIDTMV2aTwgg3eQ==',
    'Content-Type': 'application/json',
    'api-key': environment.apiKey
  });

  constructor(
    // private db: AngularFirestore,
    private http: HttpClient,
    // private storage: AngularFireStorage,
    @Inject(PLATFORM_ID) private platformId: string,
    private readonly firestore: Firestore) { }

  

  async getMyPublications(email: string) {
    const carsCollection = collection(this.firestore, 'cars');
    const q = query(carsCollection, where('user_email', '==', email));
    const querySnapshot = await getDocs(q);
    const publications = querySnapshot.docs.map(doc => ({
      idFire: doc.id,
      ...doc.data()
    }));

    return publications;
  }

  async getMyPublicationsById(id: string): Promise<any> {
    const docRef = doc(this.firestore, 'cars', id);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      return { id: docSnapshot.id, ...docSnapshot.data() };
    } else {
      return null;
    }
  }

  getFirebaseCarById(idCar:string): Observable<any>{
    const booksRef = doc(this.firestore, `cars/${idCar}`);
    return docData(booksRef, {idField: 'values'}) as Observable<any[]>;
  }

  async updatePricePublication(id: string, price: any, sellerPrice: any): Promise<any> {
    const carDocRef = doc(this.firestore, 'cars', id);
    await updateDoc(carDocRef, { price, sellerPrice });
  }

  getCars(menuParam: string, page: number, criteria?: string) {
    let params = new HttpParams();
    params = params.set('order', menuParam);
    if (criteria) params = params.append('criteria', criteria)
    return this.http.get(`${this.apiUrl}/cars/all/${page}?request=caranty&pageSize=23`, {params});
  }

  getCarById(id: any) {
    return this.http.get(`${this.apiUrl}/cars/search/${id}?request=caranty`);
  }

  public searchCar(term: any, menuParam: string, page: number) {
    let params = new HttpParams();
    params = params.set('order', menuParam);
    return this.http.get<any>(`${this.apiUrl}/cars/all/${page}?criteria=${term}&request=caranty&pageSize=23`, {params});
  }

  searchByFilters(search: any, menuParam: string, page: number, criteria?: string) {
    let params = new HttpParams();
    if(search && Array.isArray(search)){
      for (const el of search) {
        params = params.set('order', menuParam);
        if(el.append) params = params.append(el.type, el.value);
      }
    }
    if(criteria){
      params = params.append('criteria', criteria);
    }
    return this.http.get<any>(`${this.apiUrl}/cars/all/${page}?request=caranty&pageSize=23`, {params});
  }

  // public uploadCarPhoto(file: File, carId: string, picFolder: string): any {
  //   const photo = file;
  //   const name = file.name;
  //   const filePath = `Cars/${carId}/${picFolder}/${name}`;
  //   return this.storage.ref(filePath).put(photo);
  // }

  // public async getDownloadUrl(path: string): Promise<string> {
  //   return await this.storage.ref(path).getDownloadURL().toPromise();
  // }

  async getPercentage(sellerid: string): Promise<any> {
    if (sellerid) {
      const profitCollection = collection(this.firestore, 'profit');
      const q = query(profitCollection, where('sellerid', '==', sellerid));
      const querySnapshot = await getDocs(q);
      const percentages = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      if (percentages.length > 0) {
        return percentages[0];
      } else {
        return { percentage: 4, sellerid: "" };
      }
    } else {
      return { percentage: 4, sellerid: "" };
    }
  }

  public deleteBackendCar(id: any) {
    return this.http.put(`${this.apiUrl}/cars/car/disable/${id}`,{});
  }

  async deleteFirebaseCar(id: string, data?: any): Promise<void> {
    const carDocRef = doc(this.firestore, 'cars', id);
    await updateDoc(carDocRef, data || {});
  }

  getCarsInfo(urlWithParameters?: any) {
    return this.http.get<IResultAzure>(`${this.apiUrl}/${urlWithParameters}`);
  }

  async getCarInfo(idCar:string) {
    return this.http.get(`${this.apiUrl}/cars/search/${idCar}?request=caranty`);
  }

  saveSantanderCredit(body: any){
    return this.http.post(`${this.apiUrl}/rooms/addCredit`, body);
  }

  updateJsonCars(){
    const url = `${this.apiUrl}/powerautomate/executePAUpdateJsonCars`
    
    this.http.post(url, {}).subscribe(() => {});
  }

  getProbabilityOffer(price:number){
    const url = `${this.apiUrl}/probabilidad?oferta=${price}&type=azure`
    
    return this.http.get(url);
  }

  searchKeyWords(search: string){
    const url = `${this.apiUrl}/searchAutomoviles?search=${search}&type=azure`;

    return this.http.get<IResultAzure>(url);
  }

  saveFindMyCar(body: any){
    const url = `${this.apiUrl}/autoInteres?type=azure`;

    return this.http.post(url, body);
  }

  registerVisitCar(body: any  ){
    const url = `${this.apiUrl}/autovisitado?type=azure`;

    return this.http.post(url, body);
  }

  quotePrices(body: any) {
    return this.http.post<any>(`${this.apiUrl}/v2/intelimotors/evaluacion?type=azure`, body);
  }

  getAllFavorites(idUser:string){
    const url = `${this.apiUrl}/favorits?idUsuario=${idUser}&type=azure`;

    return this.http.get<IResultAzure>(url);
  }

  updateCarPrice(id: any, body: any){
    const url = `${this.apiUrl}/cars/car/${id}`

    return this.http.put(url, body);
  }

  sendMyCreditSantander(body: any){
    const url = `${this.apiUrl}/santander/credito?code=${this.azureKey}&type=azure`;

    return this.http.post<IResultAzure>(url, body);
  }

  async getTypesCar(): Promise<any[]> {
    const carTypesCollection = collection(this.firestore, 'car_types');
    const querySnapshot = await getDocs(carTypesCollection);
    const carTypes = querySnapshot.docs.map(doc => doc.data());
    return carTypes;
  }

  searchCarById(idCar: string){
    const url = `${this.apiUrl}/getAutomovilesbyID?idAutomovil=${idCar}&type=azure`;

    return this.http.get<IResultAzure>(url);
  }

  async updateCarProperties(id: string, data: any): Promise<void> {
    const carDocRef = doc(this.firestore, 'cars', id);
    await updateDoc(carDocRef, data);
  }

  async deleteCar(idCar: string): Promise<void> {
    const carDocRef = doc(this.firestore, 'cars', idCar);
    await deleteDoc(carDocRef);
  }

  async sendNotificationsPublish(body:any) {
    const url = `${this.apiUrl}/newcarpublication`;

    return await lastValueFrom(this.http.post(url, body));
  }

  getCarImages(idCar: string) {
    const params = new HttpParams().set('code', this.azureKey);
    const url = `${this.apiUrl}/web/storage/loadimage/${idCar}?type=azure`;

    return this.http.get<IResultAzure>(url, {params});
  }

  saveCarNotFound(body:any){
    const url = `${this.apiUrl}/utils/carnotfound`;

    return this.http.post(url, body);
  }

  async sendNotificationUpdatePictures(data:any){
    const url = `${this.apiUrl}/utils/changeinphotos`;

    return this.http.post(url, data);
  }

  getCarImagesDetail(idCar:string){
    const url = `${this.apiUrl}/cars/car/images/${idCar}?type=caranty`;

    return this.http.get<any>(url);
  }

  getCarsDiscount(date?: string, limit?: number, credito = false, filters?: Array<any>){
    let params = new HttpParams();
    if(limit && limit != 0) params = params.set('pageSize', limit.toString());
    if(date) params = params.set('date', date);
    if(filters && filters?.length){
      params = params.append('filter', true);
      for (const el of filters) {
        params = params.append(el.key, el.value);
      }
    }
    let url = `${this.apiUrl}/cars/changedPriceCars`;
    if(credito) params = params.append('flag', 'credito');

    return this.http.get<any>(url, {params});
  }

  getBrands() {
    return this.http.get<IResultAzure>(`${this.apiUrl}/getMarcas/disponibles?type=azure`);
  }

  getCarsHome(params: string): Observable<any> {
    const url = `${this.apiUrl}/getAutomoviles?${params}&type=azure`;

    return this.http.get<any>(url);
  }

  deleteFavorite(body: any){
    const url = `${this.apiUrl}/favorits?type=azure`;

    return this.http.request<any>('DELETE', url, {body})
  }

  saveFavorite(body: any) {
    const url = `${this.apiUrl}/favorits?type=azure`;

    return this.http.post<any>(url, body);
  }

  getFavoriteById(idUser: string, idCar: string) {
    const url = `${this.apiUrl}/favoritsbyIds?idUsuario=${idUser}&idAutomovil=${idCar}&type=azure`;

    return this.http.get<any>(url);
  }

  sendQuestion(body: any) {
    const url = `${this.apiUrl}/preguntas?type=azure&code=IeITEeTN7UQzHPbjTGoO139sWRnEKZCb5N7F36p4guUiwbvMt0mmaA==`;

    return this.http.post(url, body);
  }

  getReference(body: any) {
    const url = `${this.apiUrl}/user/addPayment`;

    return this.http.post(url, body);
  }

  sendPaymentAgency(body: any) {
    const url = `${this.apiUrl}/notificapagoagencia`;

    return this.http.post(url, body);
  }

  sendReferences(body: any) {
    const url = `${this.apiUrl}/user/sendEmailPayment`

    return this.http.post(url, body);
  }

  async sendWhatsAppNotification(body: any) {
    const url = `${this.apiUrl}/solicitud/whatsapp?code=${this.azureKey}&type=azure`;

    return await lastValueFrom(this.http.post(url, body));
  }

  public createCarBackend(body: any) {
    return this.http.post<any>(`${this.apiUrl}/cars/car?type=caranty`, body);
  }

  generateAppointment(body: any){
    const url = `${this.apiUrl}/cita_unique?code=${this.azureKey}&type=azure`;
    
    return this.http.post<IResultAzure>(url, body);
  }
}